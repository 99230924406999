//
//
//
//
//
//

export default {
  functional: true,
  props: {
    tag: {
      type: String,
      required: false,
      default: null,
    },
  },
}
