// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*purgecss start ignore*/\n.text_jOHu8{\n  font-size:1rem;\n  line-height:1.6;\n  letter-spacing:0.02em;\n  font-weight:400;\n}\n.text_jOHu8:not(:last-child), .text_jOHu8 p:not(:last-child){\n    margin-bottom:1.5rem\n}\n.text_jOHu8 p img{\n    display:inline\n}\n.text_jOHu8{\n  word-break:break-all;\n  word-break:break-word;\n  -webkit-hyphens:auto;\n      -ms-hyphens:auto;\n          hyphens:auto}\n\n/*purgecss end ignore*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "text_jOHu8"
};
module.exports = ___CSS_LOADER_EXPORT___;
