//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    tag: {
      type: String,
      required: false,
      default: 'div',
    },
  },
}
