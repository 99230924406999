//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    headerLayout: {
      type: Number,
      default: 0,
    },
  },
}
