//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CeNewsListNews',
  props: {
    header: {
      type: String,
      required: false,
      default: ''
    },
    headerLayout: {
      type: Number,
      required: false,
      default: 0
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
  }
}
